import axiosInstance from './axiosInstance';
import { ApiResponse } from './types';

export const getTakeoffData = async (
	propertyId: string
): Promise<ApiResponse<any>> => {
	const response = await axiosInstance.get(`/v2/p/${propertyId}/ee/`);

	return response.data;
};

export const getFlightPlanData = async (
	propertyId: string
): Promise<ApiResponse<any>> => {
	const response = await axiosInstance.get(
		`/v2/p/${propertyId}/ee/flight-plans`
	);

	return response.data;
};

export const getAnomalyDataFromSupabase = async (
	propertyId: string,
	flightPlanId: string
): Promise<ApiResponse<any>> => {
	const response = await axiosInstance.get(
		`/v2/p/${propertyId}/ee/model-points`,
		{
			params: { flight_plan_id: flightPlanId },
		}
	);

	return response.data;
};

export const getModelLoader = async (
	propertyId: string,
	modelType: string,
	modelName: string
): Promise<ApiResponse<any>> => {
	const response = await axiosInstance.get(
		`/v2/p/${propertyId}/ee/model-loader`,
		{
			params: { model_type: modelType, model_name: modelName },
		}
	);

	return response.data;
};

export const getImagesData = async (
	data: {} = {},
	propertyId: string
): Promise<ApiResponse<any>> => {
	const response = await axiosInstance.get(
		`/v2/p/${propertyId}/ee/images`,
		// points to new v2 endpoint
		{
			params: { data: JSON.stringify(data) },
		}
	);

	return response.data.data;
};

export const getImageMetadata = async (
	propertyId: string,
	thermalImage: string
): Promise<ApiResponse<any>> => {
	const response = await axiosInstance.get(
		`/v2/p/${propertyId}/ee/image-meta-data`,
		{
			params: { thermal_image: thermalImage },
		}
	);

	return response.data;
};

export const getImageThermalData = async (
	propertyId: string,
	thermalImage: string,
	flightPlanId: string
): Promise<ApiResponse<any>> => {
	const response = await axiosInstance.get(
		`/v2/p/${propertyId}/ee/image-thermal-data`,
		{
			params: { thermal_image: thermalImage, flight_plan_id: flightPlanId },
		}
	);

	return response.data;
};

export const getOverviewImage = async (
	propertyId: string
): Promise<ApiResponse<any>> => {
	const response = await axiosInstance.get(
		`/v2/p/${propertyId}/ee/overview-image`
	);

	return response.data;
};

export const getPCAReport = async (
	propertyId: string
): Promise<ApiResponse<any>> => {
	const response = await axiosInstance.get(
		`/v2/p/${propertyId}/ee/download-pca-report`
	);

	return response.data;
};
