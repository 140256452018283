import { useState } from 'react';
import { ChargingStationsSelectionPopup } from './ChargingStationsSelectionPopup';
import { TakeoffLocationSelectionPopup } from './TakeoffLocationSelectionPopup';
import { DateSelectionPopup } from './DateSelectionPopup';
import { droneLogisticsPageKeys } from '../../const';
import { calculateDroneLogisticsProgress } from '../../calculateSectionProgress';

export const maxSelectableDays = 90;
export const totalSelectionDays = 21;
export const notaLabel = 'None of the above';

function DroneLogistics({
	handlePopupClose,
	droneLogisticsData,
	setDroneLogisticsData,
	setWasSubmitted,
}) {
	const [pageNumToDisplay, setPageNumToDisplay] = useState(
		calculateDroneLogisticsProgress(droneLogisticsData)
	);

	const pageToDisplay = {
		[droneLogisticsPageKeys.DATE_SELECTION]: (
			<DateSelectionPopup
				onSubmit={() => {
					setPageNumToDisplay(droneLogisticsPageKeys.TAKEOFF_LOCATIONS);
				}}
				selectedDates={droneLogisticsData.selectedDates}
				setDroneLogisticsData={setDroneLogisticsData}
			/>
		),
		[droneLogisticsPageKeys.TAKEOFF_LOCATIONS]: (
			<TakeoffLocationSelectionPopup
				onSubmit={() => {
					setPageNumToDisplay(droneLogisticsPageKeys.CHARGING_STATIONS);
				}}
				onBack={() =>
					setPageNumToDisplay(droneLogisticsPageKeys.DATE_SELECTION)
				}
				takeoffLocations={droneLogisticsData.takeoffLocations}
				preferredLocations={droneLogisticsData.preferredLocations}
				setDroneLogisticsData={setDroneLogisticsData}
			/>
		),
		[droneLogisticsPageKeys.CHARGING_STATIONS]: (
			<ChargingStationsSelectionPopup
				onBack={() =>
					setPageNumToDisplay(droneLogisticsPageKeys.TAKEOFF_LOCATIONS)
				}
				chargingLocations={droneLogisticsData.chargingLocations}
				additionalNotes={droneLogisticsData.additionalNotes}
				setDroneLogisticsData={setDroneLogisticsData}
				handlePopupClose={handlePopupClose}
				setWasSubmitted={setWasSubmitted}
			/>
		),
	};

	return <>{pageToDisplay[pageNumToDisplay]}</>;
}

export default DroneLogistics;
